import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCmsProfiles,
  IUserCmsProfile,
  ServiceUserCmsProfiles,
  UserCmsProfile,
} from 'services/user-cms-profiles';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { selectUserCmsFilters, selectUserCmsOrderBy, selectUserCmsPagination } from './selectors';
import { IGridUserCms } from './slice';

const dynamic = dynamicNamespace<IUserCmsProfile>();

export const actionUserCmsGetAllDynamic = createAsyncThunk<
  { value: IGridUserCms[]; count: number },
  { page: number } | void,
  AppAsyncThunkConfig
>(`USER_CMS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { search, userCmsProfilePermissionID } = selectUserCmsFilters(getState());
    const { take, skip } = selectUserCmsPagination(getState());
    const { field, order } = selectUserCmsOrderBy(getState());
    const params = {
      filter: mergeFilters(
        dynamic.makeFilter(
          ['firstName', 'lastName', 'email'],
          search,
          decoratorIsNotNullable(contains),
        ),
        dynamic.makeFilter(
          'userCmsProfilePermissionID',
          userCmsProfilePermissionID,
          decoratorIsNotNullable(equals),
        ),
      ).join('&&'),
      select: dynamic.select(
        'id',
        'appIdentityUserID',
        'firstName',
        'lastName',
        'userCmsProfilePermissionID',
        'email',
        'userPhoto',
        'isActive',
        'userCmsProfilePermission.title as role',
      ),
      orderBy: dynamic.orderBy(field, order),
      count: true,
      take,
      skip,
    };
    const { data: result } = await ServiceUserCmsProfiles.getAllDynamic<
      IGridUserCms,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionUserCmsChangeItem = createAsyncThunk<
  void,
  Partial<UserCmsProfile>,
  AppAsyncThunkConfig
>(`USER_CMS/changeItem`, async (data, { dispatch }) => {
  try {
    const appIdentityUserID = data.appIdentityUserID;
    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserCmsProfiles.endpoints.patchUserCrmProfile.initiate({
        ...data,
        appIdentityUserID,
      }),
    ).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
