import { Box, BoxProps, CircularProgress } from '@mui/material';
import { memo } from 'react';

interface Props extends BoxProps {
  zIndex?: number;
  background?: string;
}

export const AppLoading = memo<Props>(
  ({ zIndex = 1000, background = 'rgba(255,255,255,0)', ...rest }) => {
    return (
      <Box
        zIndex={zIndex}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: background,
        }}
        {...rest}
      >
        <CircularProgress color="secondary" />
      </Box>
    );
  },
);
