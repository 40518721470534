import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentAppUser, ServiceAccounts } from 'services/accounts';
import { apiUserCmsProfiles, UserCmsProfile } from 'services/user-cms-profiles';
import { updateAuthTokens } from 'utils/cookies';
import { parseErrorData } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { AppAsyncThunkConfig } from '../index';

export const actionAccountGetUser = createAsyncThunk<
  CurrentAppUser,
  { isOnTheBackground: boolean },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountGetUser`, async (_) => {
  try {
    const { data } = await ServiceAccounts.getCurrentUser();
    return data;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountGeneratePassword = createAsyncThunk<
  void,
  { email: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountGeneratePassword`, async (data) => {
  try {
    await ServiceAccounts.generatePassword(data);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountSedCode = createAsyncThunk<
  void,
  { email: string; code: string },
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountSedCode`, async (data, { dispatch }) => {
  try {
    const {
      data: {
        expires,
        jwt: { token, refreshToken },
      },
    } = await ServiceAccounts.sendCode(data);
    updateAuthTokens({ token, refreshToken, expires });
    const getUserResult = await dispatch(actionAccountGetUser({ isOnTheBackground: false }));

    if (actionAccountGetUser.rejected.match(getUserResult)) {
      throw getUserResult.error;
    }
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionAccountLogout = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  `ACCOUNT/actionAccountLogout`,
  async () => {
    try {
      await ServiceAccounts.logout();
      updateAuthTokens();
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
export const actionAccountChangeData = createAsyncThunk<
  void,
  PatchPartial<UserCmsProfile, 'appIdentityUserID'>,
  AppAsyncThunkConfig
>(`ACCOUNT/actionAccountChangeData`, async (data, { dispatch }) => {
  try {
    await dispatch(
      apiUserCmsProfiles.endpoints.patchUserCrmProfile.initiate({
        ...data,
        appIdentityUserID: data.appIdentityUserID,
      }),
    ).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
