import { Announcement, Dashboard, Settings } from '@mui/icons-material';
import { Box, ClickAwayListener, Drawer } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs';
import { useOpen, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { Languages } from '../languages';
import { MenuLogo } from '../menu-logo';
import { Navigation } from '../navigation';
import style from './index.module.scss';

interface Props {}

export const Panel: React.FC<Props> = () => {
  const { tp } = useTranslate();

  const { isOpen, onToggle, onClose, onOpen } = useOpen();

  const options = useMemo(() => {
    return [
      {
        title: tp('dashboard'),
        Icon: <Dashboard color={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
      {
        title: tp('content'),
        Icon: <Announcement color={'inherit'} />,
        options: [
          {
            title: tp('home-page'),
            to: APP_ROUTES.HOME_PAGE().config,
          },
          {
            title: tp('about-us'),
            to: APP_ROUTES.ABOUT_US().config,
          },
          {
            title: tp('privacy-policy'),
            to: APP_ROUTES.PRIVACY_POLICY().config,
          },
          {
            title: tp('terms-and-conditions'),
            to: APP_ROUTES.TERMS_AND_CONDITIONS().config,
          },
          {
            title: tp('contact-us'),
            to: APP_ROUTES.CONTACT_US().config,
          },
          {
            title: tp('apowiser-products'),
            to: APP_ROUTES.APOWISER_PRODUCTS().config,
          },
          {
            title: tp('benefits'),
            to: APP_ROUTES.BENEFITS().config,
          },
          {
            title: tp('business-values'),
            to: APP_ROUTES.BUSINESS_VALUES().config,
          },
          {
            title: tp('customers'),
            to: APP_ROUTES.CUSTOMERS().config,
          },
          {
            title: tp('news'),
            to: APP_ROUTES.NEWS().config,
          },
          {
            title: tp('team-members'),
            to: APP_ROUTES.TEAM_MEMBERS().config,
          },
          {
            title: tp('tractions'),
            to: APP_ROUTES.TRACTIONS().config,
          },
          {
            title: tp('labels'),
            to: APP_ROUTES.LABELS().config,
          },
        ],
      },
      {
        title: tp('settings'),
        Icon: <Settings color={'inherit'} />,
        options: [
          {
            title: tp('cms-users'),
            to: APP_ROUTES.CMS_USERS().config,
          },
          {
            title: tp('permissions'),
            to: APP_ROUTES.PERMISSIONS().config,
          },
          {
            title: tp('app-logs'),
            to: APP_ROUTES.APP_LOGS().config,
          },
          {
            title: tp('system-settings'),
            to: APP_ROUTES.SYSTEM_SETTINGS().config,
          },
        ],
      },
    ];
  }, [tp]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Drawer
        open={isOpen}
        variant={'permanent'}
        onClose={onClose}
        classes={{
          paper: clsx(style.paper, isOpen && style.paperOpen),
        }}
      >
        <Box className={style.inner}>
          <MenuLogo isOpen={isOpen} onToggle={onToggle} />
          <Box>
            <Navigation
              isMenuOpen={isOpen}
              onMenuOpen={onOpen}
              onMenuClose={onClose}
              options={options}
            />
          </Box>
          <Languages />
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};
