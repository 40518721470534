import { UserCmsProfilePermissions } from 'services/user-cms-profile-permissions';
import * as yup from 'yup';

const API = 'UserCrmProfiles';

export const API_USER_CMS_PROFILES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<UserCmsProfile, 'appIdentityUserID'>) =>
    `${API}/Patch/${data.appIdentityUserID}`,
  DELETE: (data: Pick<UserCmsProfile, 'appIdentityUserID'>) =>
    `${API}/Delete/${data.appIdentityUserID}`,
};

export const schemaUserCmsProfile = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length'),
  email: yup.string().nullable().required('rule-required').email('rule-email'),
  languageID: yup.string().nullable().required('rule-required'),
  userCmsProfilePermissionID: yup.string().nullable().required('rule-required'),
});
export class UserCmsProfile implements yup.InferType<typeof schemaUserCmsProfile> {
  id!: string;
  isActive = true;
  appIdentityUserID!: string;
  languageID!: string;
  email = '';
  userPhoto = '';
  firstName = '';
  lastName = '';
  position = '';
  userCmsProfilePermissionID!: string;
}

export interface IUserCmsProfile extends UserCmsProfile {
  userCmsProfilePermissions: UserCmsProfilePermissions;
}
