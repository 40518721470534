import { actionAccountChangeData, actionAccountGetUser } from 'store/auth/actions';
import { actionUserCmsRevalidateData } from 'store/user-cms';
import { debounce, put } from 'typed-redux-saga';

function* watcherAccountChange() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
  yield* put(actionUserCmsRevalidateData());
}
export const sagasAccounts = [
  debounce(5000, actionAccountChangeData.fulfilled, watcherAccountChange),
];
