import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.cmsPermissions;

export const selectCmsPermissionsStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectCmsPermissionsData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectCmsPermissionsOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
