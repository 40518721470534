import { actionAccountGetUser } from 'store/auth/actions';
import { debounce, put } from 'typed-redux-saga';
import { actionUserCmsChangeItem } from './actions';

function* watcherAccountChange() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
}
export const sagasUserCms = [
  debounce(5000, actionUserCmsChangeItem.fulfilled, watcherAccountChange),
];
