import { Reducer, useCallback, useReducer, useRef } from 'react';
import { DynamicOrder } from 'utils/dynamic';

interface Pagination {
  page: number;
}

interface State<F> {
  filters: F;
  pagination: Pagination;
  orderBy: DynamicOrder;
}

type Actions<F> =
  | { type: 'FILTERS_SET'; payload: Partial<F> }
  | { type: 'FILTERS_RESET'; payload: F }
  | { type: 'PAGE_SET'; payload: Partial<Pagination> }
  | { type: 'ORDER_SET'; payload: Partial<DynamicOrder> };

const reducer = <F = any>(state: State<F>, action: Actions<F>): State<F> => {
  switch (action.type) {
    case 'FILTERS_RESET':
      return { ...state, filters: action.payload, pagination: { ...state.pagination, page: 1 } };
    case 'FILTERS_SET':
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
        pagination: { ...state.pagination, page: 1 },
      };
    case 'PAGE_SET':
      return {
        ...state,
        pagination: { ...state.pagination, ...action.payload },
      };
    case 'ORDER_SET':
      return {
        ...state,
        orderBy: { ...state.orderBy, ...action.payload },
        pagination: { ...state.pagination, page: 1 },
      };
  }
  return state;
};

export const useRtkQueryReducer = <F>(initialState: State<F>) => {
  const refInitState = useRef(initialState);
  const [state, dispatch] = useReducer<Reducer<State<F>, Actions<F>>>(reducer, initialState);

  const filtersReset = useCallback(() => {
    dispatch({ type: 'FILTERS_RESET', payload: refInitState.current.filters });
  }, []);
  const filtersSet = useCallback((filters: Partial<F>) => {
    dispatch({ type: 'FILTERS_SET', payload: filters });
  }, []);

  const paginationSet = useCallback((data: Partial<Pagination>) => {
    dispatch({ type: 'PAGE_SET', payload: data });
  }, []);

  const orderSet = useCallback((data: Partial<DynamicOrder>) => {
    if (data.order === null && data.field !== refInitState.current.orderBy.field) {
      return dispatch({ type: 'ORDER_SET', payload: refInitState.current.orderBy });
    }
    dispatch({ type: 'ORDER_SET', payload: data });
  }, []);

  return { state, dispatch, filtersReset, filtersSet, paginationSet, orderSet };
};
