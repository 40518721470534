const API = 'AppLogs';

export const API_APP_LOGS = {
  GET_ALL_DYNAMIC: `${API}/GetAppLogsDynamic`,
  GET_TYPES: `${API}/GetAppLogTypesDynamic`,
};

export interface IAppLog {
  id: string;
  createdDate: string;

  appLogSourceID: string;
  appLogSource: any;

  appLogTypeID: string;
  appLogType: AppLogType;

  appUserID: string | null;
  isActive: boolean;

  message: string;
  messageDetails: string | null;
  reflectionMetaData: string;
}
export interface AppLogType {
  id: string;
  title: string;
}
export interface IAppLogGrid
  extends Pick<
    IAppLog,
    'id' | 'createdDate' | 'reflectionMetaData' | 'message' | 'appUserID' | 'messageDetails'
  > {}
