import {
  AppConfigProvider,
  AppNotificationsProvider,
  AppThemeProvider,
  AuthProvider,
  AxiosInterceptorsProvider,
  DatePickerProvider,
  TranslateProvider,
} from 'contexts';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { AppRoutes } from 'router';
import { store } from 'store';
import 'styles/app.global.scss';

function App() {
  return (
    <AppConfigProvider>
      <AppNotificationsProvider>
        <Provider store={store}>
          <AxiosInterceptorsProvider>
            <AppThemeProvider>
              <TranslateProvider>
                <AuthProvider>
                  <DatePickerProvider>
                    <DndProvider backend={HTML5Backend}>
                      <AppRoutes />
                    </DndProvider>
                  </DatePickerProvider>
                </AuthProvider>
              </TranslateProvider>
            </AppThemeProvider>
          </AxiosInterceptorsProvider>
        </Provider>
      </AppNotificationsProvider>
    </AppConfigProvider>
  );
}

export default App;
