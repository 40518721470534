import { AppSelectProps } from 'components/app-select';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { composeFunctions } from 'utils/other';
import { useFieldProps } from './use-field-props';

interface Options {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
}

export const useFilterSelectProps = <T extends AppSelectProps<any, any, any, any>>(
  props: T & { onChangeItem: () => void },
) => {
  const getFieldProps = useFieldProps();
  const { onChangeItem, ...rest } = props;
  return useCallback(
    (options: Options) => {
      const { field } = options;
      return {
        ...rest,
        ...getFieldProps(options),
        fullWidth: true,
        helperText: undefined,
        onChange: composeFunctions(field.onChange, onChangeItem),
      };
    },
    [rest, getFieldProps, onChangeItem],
  );
};
