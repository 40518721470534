import { AppLoading } from 'components/app-loading';
import { APP_ROUTES } from 'configs';
import { EmptyLayout, LabelsLayout, LoginLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { LABEL_SOURCE_KEYS } from 'services/label-sources';

const DashboardPage = React.lazy(() => import('pages/dashboard'));

const HomePage = React.lazy(() => import('pages/common-pages/component/pages/homepage'));
const AboutUsPage = React.lazy(() => import('pages/common-pages/component/pages/about-us'));
const BusinessValuesPage = React.lazy(() => import('pages/business-values'));
const ApowiserProductsPage = React.lazy(() => import('pages/apo-products'));
const BenefitsPage = React.lazy(() => import('pages/benefits'));
const CustomersPage = React.lazy(() => import('pages/customers'));
const TeamMembersPage = React.lazy(() => import('pages/team-members'));
const NewsPage = React.lazy(() => import(/* webpackChunkName: "news" */ 'pages/news-items'));
const TractionsPage = React.lazy(() => import('pages/company-tractions'));
const ContactUsPage = React.lazy(() => import('pages/common-pages/component/pages/contact-us'));
const PrivacyPolicyPage = React.lazy(
  () => import('pages/common-pages/component/pages/privacy-policy'),
);
const TermsAndConditionsPage = React.lazy(
  () => import('pages/common-pages/component/pages/terms-and-conditions'),
);

const ProfilePage = React.lazy(() => import('pages/profile'));
const CmsUsersPage = React.lazy(() => import('pages/cms-profiles'));
const PermissionsPage = React.lazy(() => import('pages/cms-permissions'));

const LabelsContainer = React.lazy(() => import('pages/labels'));
const SystemSettingsPage = React.lazy(() => import('pages/system-settings'));
const AppLogsPage = React.lazy(() => import(/* webpackChunkName: "app-logs" */ 'pages/app-logs'));

const LoginPage = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const LoginCodePage = React.lazy(() => import('pages/login-code'));

const NotFoundPage = React.lazy(() => import('pages/todo'));

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <DashboardPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.HOME_PAGE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <HomePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ABOUT_US.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <AboutUsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BUSINESS_VALUES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <BusinessValuesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.APOWISER_PRODUCTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ApowiserProductsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BENEFITS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <BenefitsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CUSTOMERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CustomersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.TEAM_MEMBERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <TeamMembersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.NEWS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NewsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.TRACTIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <TractionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CONTACT_US.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ContactUsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PRIVACY_POLICY.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PrivacyPolicyPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.TERMS_AND_CONDITIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <TermsAndConditionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_USERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsUsersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PERMISSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PermissionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LABELS.path,
        element: <LabelsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer labelSourceKey={LABEL_SOURCE_KEYS.WEBSITE} />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer labelSourceKey={LABEL_SOURCE_KEYS.CMS} />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.APP_LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <AppLogsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SYSTEM_SETTINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <SystemSettingsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
