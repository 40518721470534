import * as dynamic from 'utils/dynamic';
import { apiRtk, decoratorWithFiles, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_USER_CMS_PROFILES, UserCmsProfile } from './models';

export * from './models';

type Model = UserCmsProfile;
const REVALIDATE_TAG = 'UserCrmProfiles';

class Service extends DynamicService<Model> {
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async post(data: Model) {
    return super.post(data);
  }
  @decoratorWithFiles('appIdentityUserID', 'userPhoto')
  async patch(data: Partial<Model>) {
    return super.patch(data);
  }
}

export const ServiceUserCmsProfiles = new Service({
  mainField: 'appIdentityUserID',
  getAll: API_USER_CMS_PROFILES.GET_ALL_DYNAMIC,
  post: API_USER_CMS_PROFILES.POST,
  patch: API_USER_CMS_PROFILES.PATCH,
  delete: API_USER_CMS_PROFILES.DELETE,
});

interface UserCmsProfileSource extends Pick<UserCmsProfile, 'id' | 'lastName' | 'firstName'> {}
interface UserCmsProfileInfo extends Pick<UserCmsProfile, 'userPhoto'> {
  fullName: string;
}

export const apiUserCmsProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCrmProfileSource: build.query<UserCmsProfileSource[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserCmsProfiles.getAllDynamic<UserCmsProfileSource>({
            select: dynamic.select<Model>('appIdentityUserID as id', 'firstName', 'lastName'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    getUserCrmProfile: build.query<Model, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUserCmsProfiles.getDynamic(appIdentityUserID);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    getUserCrmProfileInfo: build.query<UserCmsProfileInfo, string>({
      queryFn: async (appIdentityUserID) => {
        try {
          const { data } = await ServiceUserCmsProfiles.getDynamic<UserCmsProfileInfo>(
            appIdentityUserID,
            {
              take: 1,
              select: dynamic.select<Model>('userPhoto', 'fullName'),
            },
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_TAG, id }],
    }),
    postUserCrmProfile: build.mutation<void, Model>({
      queryFn: async (data) => {
        try {
          await ServiceUserCmsProfiles.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserCmsProfiles.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
    deleteUserCrmProfile: build.mutation<void, PatchPartial<Model, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await ServiceUserCmsProfiles.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { appIdentityUserID }) => [
        { type: REVALIDATE_TAG, id: appIdentityUserID },
      ],
    }),
  }),
});
