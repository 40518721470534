import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  HOME_PAGE: createTypedRoute('/home-page'),
  ABOUT_US: createTypedRoute('/about-us'),
  BUSINESS_VALUES: createTypedRoute('/business-values'),
  APOWISER_PRODUCTS: createTypedRoute('/apowiser-products'),
  BENEFITS: createTypedRoute('/benefits'),
  CUSTOMERS: createTypedRoute('/customers'),
  TEAM_MEMBERS: createTypedRoute('/team-members'),
  NEWS: createTypedRoute('/news'),
  TRACTIONS: createTypedRoute('/tractions'),
  CONTACT_US: createTypedRoute('/contact-us'),
  PRIVACY_POLICY: createTypedRoute('/privacy-policy'),
  TERMS_AND_CONDITIONS: createTypedRoute('/terms-and-conditions'),
  SETTINGS: createTypedRoute('/settings'),
  CMS_USERS: createTypedRoute('/settings/cms-users'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),

  LABELS: createTypedRoute('/labels'),
  CMS: createTypedRoute('/labels/cms'),

  SYSTEM_SETTINGS: createTypedRoute('/settings/system-settings'),
  APP_LOGS: createTypedRoute('/settings/app-logs'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),

  PROFILE: createTypedRoute('/profile'),
};
