import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCmsProfilePermissions } from 'services/user-cms-profile-permissions';
import { actionCmsPermissionsChangeItem, actionCmsPermissionsGetAllDynamic } from './actions';

interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}
export interface IGridCmsPermissions extends UserCmsProfilePermissions {}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridCmsPermissions[];
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    orderBy: {
      field: 'title',
      order: 'asc',
    },
  };
};

const slice = createSlice({
  name: 'CMS_PERMISSIONS',
  initialState: initState(),
  reducers: {
    actionCmsPermissionsOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionCmsPermissionsChangeItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(actionCmsPermissionsChangeItem.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(actionCmsPermissionsChangeItem.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    builder.addCase(actionCmsPermissionsGetAllDynamic.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(actionCmsPermissionsGetAllDynamic.fulfilled, (state, action) => {
      const {
        payload: { value },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
    });
    builder.addCase(actionCmsPermissionsGetAllDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const { actionCmsPermissionsOrderBySet } = slice.actions;
export const reducerCmsPermissions = slice.reducer;
