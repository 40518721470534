import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
  orderBy,
} from 'utils/dynamic';
import {
  apiRtk,
  DynamicResult,
  transformResponseDynamic,
  transformResponseDynamicItem,
} from 'utils/service';
import { API_APP_LOGS, AppLogType, IAppLog, IAppLogGrid } from './models';

const REVALIDATE_KEY = 'AppLogs' as const;

const dynamic = dynamicNamespace<IAppLog>();

export * from './models';

const selectAppLogGrid = dynamic.select(
  'id',
  'createdDate',
  'reflectionMetaData',
  'message',
  'messageDetails',
  'appUserID',
);

export const apiAppLogs = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAppLogsGrid: build.query<
      DynamicResult<IAppLogGrid, { count: true }>,
      {
        search: string;
        dates: string[];
        appLogTypeID: string;
        take: number;
        skip: number;
        orderBy: DynamicOrder;
      }
    >({
      query: ({ search, appLogTypeID, dates, take, skip, orderBy }) => ({
        url: API_APP_LOGS.GET_ALL_DYNAMIC,
        params: {
          select: selectAppLogGrid,
          filter: mergeFilters(
            dynamic.makeFilter(
              ['appLogType.title', 'message', 'reflectionMetaData'],
              search,
              contains,
            ),
            dynamic.makeFilter('appLogTypeID', appLogTypeID, decoratorIsNotNullable(equals)),
            dynamic.makeFilter('createdDate', dates, dateRange),
          ).join('&&'),
          orderBy: dynamic.orderBy(orderBy.field, orderBy.order),
          take,
          skip,
          count: true,
        },
      }),
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getAppLogGrid: build.query<IAppLogGrid, string>({
      query: (id) => ({
        url: API_APP_LOGS.GET_ALL_DYNAMIC,
        params: {
          select: selectAppLogGrid,
          filter: mergeFilters(dynamic.makeFilter('id', id, equals)).join('&&'),
          take: 1,
        },
      }),
      transformResponse: transformResponseDynamicItem,
    }),
    getAppLogsTypesSource: build.query<AppLogType[], void>({
      query: () => ({
        url: API_APP_LOGS.GET_TYPES,
        params: {
          orderBy: orderBy('title', 'asc'),
        },
      }),
      transformResponse: transformResponseDynamic,
    }),
  }),
});
