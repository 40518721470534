import { select } from 'utils/dynamic';
import { apiRtk, DynamicService } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_USER_CMS_PROFILE_PERMISSIONS, UserCmsProfilePermissions } from './models';

export * from './models';

type Model = UserCmsProfilePermissions;
const REVALIDATE_TAG = 'UserCmsProfilePermissions';

type Source = Pick<Model, 'id' | 'title'>;
class Service extends DynamicService<Model> {}

export const ServiceUserCmsProfilePermissions = new Service({
  getAll: API_USER_CMS_PROFILE_PERMISSIONS.GET_ALL_DYNAMIC,
  post: API_USER_CMS_PROFILE_PERMISSIONS.POST,
  patch: API_USER_CMS_PROFILE_PERMISSIONS.PATCH,
  delete: API_USER_CMS_PROFILE_PERMISSIONS.DELETE,
});

export const apiUserCmsProfilePermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCmsProfilePermissionSource: build.query<Source[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceUserCmsProfilePermissions.getAllDynamic<Source>({
            select: select<Model>('id', 'title'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_TAG }],
    }),
    patchUserCmsProfilePermission: build.mutation<void, PatchPartial<Model, 'id'>>({
      queryFn: async (customer) => {
        try {
          await ServiceUserCmsProfilePermissions.patch(customer);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: REVALIDATE_TAG, id }],
    }),
  }),
});
