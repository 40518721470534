import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCmsProfilePermissions,
  IUserCmsProfilePermissions,
  ServiceUserCmsProfilePermissions,
} from 'services/user-cms-profile-permissions';
import { AppAsyncThunkConfig } from 'store';
import { dynamicNamespace } from 'utils/dynamic';
import { parseErrorData } from 'utils/service';
import { selectCmsPermissionsOrderBy } from './selectors';
import { IGridCmsPermissions } from './slice';

const dynamic = dynamicNamespace<IGridCmsPermissions>();

export const actionCmsPermissionsGetAllDynamic = createAsyncThunk<
  { value: IGridCmsPermissions[] },
  void,
  AppAsyncThunkConfig
>(`CMS_PERMISSIONS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { field, order } = selectCmsPermissionsOrderBy(getState());
    const params = {
      select: dynamic.select(
        'id',
        'isActive',
        'title',
        'isAllowToEditSetting',
        'isAllowToEditLabels',
        'isAllowToEditProfile',
        'isAllowToEditCrmUsers',
        'isAllowToEditPermissions',
        'isAllowToViewLog',
        'isAllowToViewSensitiveInformation',
        'isAllowToResetSensitiveInformation',
      ),
      orderBy: dynamic.orderBy(field as any, order),
    };
    const { data: result } = await ServiceUserCmsProfilePermissions.getAllDynamic<
      IGridCmsPermissions,
      typeof params
    >(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionCmsPermissionsChangeItem = createAsyncThunk<
  void,
  Partial<IUserCmsProfilePermissions>,
  AppAsyncThunkConfig
>(`CMS_PERMISSIONS/changeItem`, async (data, { dispatch }) => {
  try {
    const id = data.id;
    if (!id) {
      throw new Error('id is required');
    }
    await dispatch(
      apiUserCmsProfilePermissions.endpoints.patchUserCmsProfilePermission.initiate({
        ...data,
        id,
      }),
    );
    await dispatch(actionCmsPermissionsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
